/* You can add global styles to this file, and also import other style files */
@import "~ngx-smart-modal/ngx-smart-modal";
@import "../node_modules/ngx-toastr/toastr.css";
@import '~@ngx-share/button/themes/default/default-theme';
@import "../node_modules/sweetalert2/dist/sweetalert2.min.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";

.lang-ar .nsm-dialog-btn-close{
    right: initial;
    left:8px;
}
